/**
 * Format's price with currency.
 * withDecimals: If true, the price will be formatted with 2 decimals
 * withCurrency: If true, the price will be formatted with currency symbol
 * NOTE: !withDecimals rounds up to the nearest integer
 * README: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#fractiondigits_significantdigits_and_integerdigits
 *
 * @param {Number} price
 * @param {Boolean} withDecimals format with decimals
 * @param {Boolean} withCurrency format with currency
 * @returns {String} formatted price string
 */
export function formatPrice(
  price: number,
  withDecimals = true,
  withCurrency = true
) {
  const formatOptions = {
    style: withCurrency ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: withDecimals ? 2 : 0,
    maximumFractionDigits: withDecimals ? 2 : 0,
  }

  if (isNaN(price)) return '-'

  return new Intl.NumberFormat('en-US', formatOptions).format(price)
}
