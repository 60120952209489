import { gql } from '@apollo/client'

const coreProductFields = `
  productId
  productAvailableQuantity
  productTotalNumberOfItemsPerPurchase
  productMinimumOrderQuantity
  productTaxType
  productDiscountAmount
  productDiscountPercent
  productDiscountReason
  productDiscountNotes
  productPaymentPlanDepositAtCheckout
  productPaymentPlanFirstIncrementPaymentDue
  productPaymentPlanIncrementsInterval
  productPaymentPlanIncrementsTotal
  productListingPrice
  productPricePerHashrate
  productCondition
  productImagesPrioritized
  productVideosPrioritized
  isProductFeatured
  isProductTurnkey
  countryName
`

const coreMinerFields = `
  minerModelId
  minerModelAlgorithm
  minerModelType
  minerModelHashratePerTerahash
  minerModelName
  minerModelPowerConsumptionWatts
  minerModelWeightInLbs
  minerModelManufactureDate
  minerModelManufacturerWarrantyExp
  monthlyHostingCharges
  manufacturerName
  compassScore
  productType
  productDisplayCategory
`

// Fragment of main fields for allAvailableProducts query related to product
export const CORE_SINGLE_PRODUCT_FIELDS = gql`
  fragment CoreSingleProductFields on AvailableProduct {
    ${coreProductFields}
  }
`

// Fragment of main fields for allBestMinerProducts query related to product
export const CORE_BEST_PRODUCT_FIELDS = gql`
  fragment CoreBestProductFields on AvailableProduct {
    ${coreProductFields}
  }
`

// Fragment of main fields for allAvailableProducts query related to miner
export const CORE_SINGLE_MINER_FIELDS = gql`
  fragment CoreSingleMinerFields on AvailableProduct {
    ${coreMinerFields}
  }
`

// Fragment of main fields for allBestMinerProducts query related to miner
export const CORE_BEST_MINER_FIELDS = gql`
  fragment CoreBestMinerFields on AvailableProduct {
    ${coreMinerFields}
  }
`

// common optional fields for hosted, non_hosted and marketplace products
export const COMMON_PRODUCT_OPTIONAL_FIELDS = gql`
  fragment CommonProductOptionalFields on AvailableProduct {
    countryName
    facilityId
    facilityDisplayName
    hostedPrepaymentMonths
    hostedDepositMonths
    hostedContractLengthMonths
    hostedTargetOnlineDate
    nonhostedEarliestShippingDate
    nonhostedShippingTimeDescription
    marketplacePrepaymentMonths
    marketplaceDepositMonths
  }
`
